<ejs-pivotview
  #pivot
  style="display: block"
  width="100%"
  height="100%"
  [dataSourceSettings]="dataSourceSettings$ | async"
  [showFieldList]="config.props?.showFieldList ?? false"
  [showGroupingBar]="config.props?.showGroupingBar ?? false"
  [allowGrouping]="config.props?.allowGrouping ?? false"
  [allowConditionalFormatting]="true"
  [allowDrillThrough]="true"
  [allowExcelExport]="true"
  [enableValueSorting]="true"
  [displayOption]="config.props?.displayOption"
  [gridSettings]="config.props?.gridSettings"
  [chartSettings]="config.props?.chartSettings"
  (dataBound)="dataBound()"
></ejs-pivotview>

<ng-template #expandCollapseTemplate>
  <div class="expand-collapse-container">
    <button mat-icon-button [matTooltip]="'expand_all' | intl" (click)="expandAll()"><mat-icon>unfold_more</mat-icon></button>
    <button mat-icon-button [matTooltip]="'collapse_all' | intl" (click)="collapseAll()"><mat-icon>unfold_less</mat-icon></button>
  </div>
</ng-template>
